import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SecondPageHeader from "../container/secondPageHaaderContainer"
import CallToAction from "../container/ctaContainer"
import { InquiryForm } from "../components/InquiryForm"

const TrialPage = () => (
  <Layout>
    <Seo title="資料請求 | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | ワカチエ" />
    <SecondPageHeader englishTitle="- CATALOG DOWNLOAD -" mainTitle="資料請求" isShowTrialLink={false} />
    <div className="InquiryForm secondPageContens">
      <InquiryForm/>
    </div>
    <CallToAction />
  </Layout>
)

export default TrialPage
